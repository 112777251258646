import React from "react";
import {
  FaCalendarAlt,
  FaEnvelopeOpenText,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaTwitter,
} from "react-icons/fa";
import img from "../assets/images/about/avatar.png";
import downloadIcon from "../assets/images/download.png";
import ResumePDF from "../assets/pdf/Resume-CV.pdf";

const HomeCard = () => {
  return (
    <div>
      <div className="select-none w-full mb-6 lg:mb-0  mx-auto   relative bg-white text-center dark:bg-[#111111] px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 ">
        <img
          src={img}
          className="absolute w-[240px] left-[50%] transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto  rounded-[20px] -mt-[140px] transition duration-150 hover:scale-105 ease-in"
          alt=""
        />
        {/* Social card */}
        <div className="pt-[100px] pb-8">
          <h1 className="mt-6 mb-1 text-5xl font-semibold  dark:text-white">
            El Aaqdi Aiman
          </h1>
          <h3 className="mb-4 text-[#7B7B7B] inline-block dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6] text-sm">
            <span className="text-[#e14a84]">Full Stack</span> Web Developer{" "}
            <br />
            <span className="text-[#e14a84]">Ethical</span> Hacker
          </h3>

          {/* Social Links */}

          <div className="flex justify-center space-x-3">
            {/* facebook link add here */}
            {/*
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#1773EA]">
                <FaFacebookF />
              </span>
            </a> 
            */}
            {/* twitter link add here */}
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#1C9CEA]">
                <FaTwitter />
              </span>
            </a>
            {/* drop link add here */}
            {/* <a
              href="https://dribbble.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#e14a84] ">
                <FaDribbble />
              </span>
            </a> */}
            {/* linkedin link add here */}
            <a
              href="https://www.linkedin.com/in/aiman-el-aaqdi-5a1507226/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#0072b1]">
                <FaLinkedinIn />
              </span>
            </a>
          </div>

          {/* personal information */}
          <div className="p-7 rounded-2xl mt-7  bg-[#F3F6F6] dark:bg-[#1D1D1D]">
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] pb-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#E93B81] shadow-md">
                <a href="tel:+33659289604">
                  <FaMobileAlt className="cursor-pointer" />
                </a>
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Phone
                </p>
                <a href="tel:+33659289604">
                  <p className="dark:text-white cursor-pointer">
                    +33 6 59 28 96 04
                  </p>
                </a>
              </div>
            </div>
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#6AB5B9] shadow-md">
                <a href="mailto:aiman.elaaqdi@gmail.com">
                  <FaEnvelopeOpenText className="cursor-pointer" />
                </a>
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Email
                </p>
                <a href="mailto:aiman.elaaqdi@gmail.com">
                  <p className="dark:text-white cursor-pointer">
                    aiman.elaaqdi@gmail.com
                  </p>
                </a>
              </div>
            </div>
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#FD7590] shadow-md">
                <FaMapMarkerAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Location
                </p>
                <p className="dark:text-white">Europe</p>
              </div>
            </div>
            <div className="flex  py-2.5">
              <span className="socialbtn bg-white dark:bg-black text-[#C17CEB] shadow-md">
                <FaCalendarAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Birthday
                </p>
                <p className="dark:text-white">Feb 2, 1999</p>
              </div>
            </div>
          </div>
          <button
            className="flex items-center mx-auto bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l hover:from-[#DD2476]  hover:to-[#fa5252ef] px-8 py-3 text-lg text-white rounded-[35px] mt-6 hover:scale-105 hover:duration-100"
            onClick={() => {
              window.open(ResumePDF);
            }}
          >
            <img src={downloadIcon} alt="icon" className="mr-2" />
            Download CV
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
