import React from "react";
import UseData from "../Hooks/UseData";

const Footer = ({ bg, condition }) => {
  const { local } = UseData();

  return (
    <footer
      style={{
        background: `${
          local === "light" ? bg : condition ? "#212425" : "transparent"
        }`,
      }}
      className="overflow-hidden rounded-b-2xl"
    >
      <p className="text-center py-6 text-gray-lite  dark:text-color-910 ">
        © {new Date().getFullYear()} - Made with ❤️ by{" "}
        <span className="hover:text-[#FA5252] duration-300 transition">
          El Aaqdi Aiman
        </span>
      </p>
    </footer>
  );
};

export default Footer;
